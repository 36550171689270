html {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;

  font-family: 'Roboto', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d3958 !important;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}
#root > div {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

#root > div > main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  padding: 0;
}

::-webkit-scrollbar  {
  width: 12px;
}

::-webkit-scrollbar-thumb  {
  background-color: #c9c9c9;
  border-radius: 5px;
}

/*
html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/

.shandle {
  z-index: 200;
  position: absolute;
  cursor: pointer;
  background-color: #0d3958;
  box-sizing: border-box;
  /* transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.shandle:hover {
  box-shadow: 0px 0px 0px 8px rgba(13, 57, 88, 0.16);
}

.slabel {
  position: absolute;
  z-index: -20;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -0.5em;
  font-size: 0.85rem;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  text-align: center;
}

.sbox {
  position: absolute;
  z-index: -20;
  left: 0;
  top: 0;
}

/* Used for port ruler labels */
.tooltipTransparent {
  font-size: 12px;
  background: transparent !important;
  box-shadow: none !important;
}

/* Used for bollards and fender labels */
.tooltipRedWithArrow {
  font-size: 13px;
  color: white !important;
  background: red !important;
}

#deckgl-wrapper .mapboxgl-popup-content {
  display: flex;
  font-size: 15px;
  padding: 10px 16px !important;
  border-radius: 4px !important;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  white-space: nowrap;
  line-height: 1.5;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  opacity: .95;
  color: gray;
  background-color: rgb(255, 255, 255);
}

#deckgl-wrapper .mapboxgl-popup-content-yard-safe .mapboxgl-popup-content {
  padding: 0 !important;
  border-radius: 8px !important;
  font-family: 'Roboto', 'Helvetica', sans-serif;
}

#deckgl-wrapper .mapboxgl-popup-content-yard-safe  .mapboxgl-popup-close-button{
  color: #FFF;
  font-size: 34px !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group{
  width: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group button {
  width: 100%;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-compass.mapboxgl-ctrl-icon{
  height: 45px;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-compass.mapboxgl-ctrl-icon .mapboxgl-ctrl-icon{
  height: 45px;
  display: flex;
  padding-top: 2px;
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-compass.mapboxgl-ctrl-icon .mapboxgl-ctrl-icon:before{
  content: 'N';
  position: absolute;
  width: 100%;
  font-weight: bold;
}

#deckgl-wrapper .deck-tooltip {
  font-family: 'Roboto', 'sans-serif';
  font-size: 0.9em !important;
}

.mapboxgl-popup-content .mapboxgl-popup-close-button {
  right: 2px;
  top: 2px;
  font-size: 20px;
  position: absolute;
  border: none;
  background: none;
}

.tooltipCCPortAsset {
  font-size: 15px;
  padding: 12px 16px!important;
  border-radius: 12px!important;
}
.tooltipCCPortAsset-name {
  font-weight:bold;
  border-bottom:solid 1px black;
  margin-bottom:10px;
}
.tooltipCCPortAsset-frame {
  display: flex;
  flex-direction: column;
}
.tooltipCCPortAsset-group {
  display: flex;
}
.tooltipCCPortAsset-group > .tooltipCCPortAsset-title {
  width: 170px;
}

.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
      -o-user-select: none;
         user-select: none;
}